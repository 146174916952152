<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Historial de servicios</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <!--    <button class="btn btn-primary shadow-md mr-2">Nuevo servicio</button> -->
        <div class="hidden md:block mx-auto text-gray-600">
          {{ servicios.length }} Registros
        </div>
        <!--         <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
 <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div> -->
      </div>
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(datos, i) in servicios"
        :key="i"
        class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
      >
        <div
          class="box"
          :style="{
            'background-color': definirColor(datos?.statusName?.toUpperCase())
          }"
        >
          <div class="text-center lg:text-left p-5">
            <button
              class="btn btn-danger py-1 px-2 float-right"
              @click="eliminar(datos)"
            >
              <Trash2Icon class="block mx-auto" />
            </button>
            <div>
              <label class="font-medium">Fecha:</label>
              {{
                new Date(datos.realDate).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })
              }}
            </div>
            <div class="mt-2">
              <label class="font-medium">Servicio Prestado:</label>
              {{ datos?.serviceTypeId ?? datos?.deliveryType }}
            </div>
            <div class="text-center lg:text-left mt-3 lg:mt-0">
              <div>
                <label class="font-medium">Usuario: </label>
                {{ datos?.userName?.toUpperCase() }}
              </div>
              <div>
                <label class="font-medium">Teléfono: </label>
                {{ datos?.userPhone }}
              </div>
              <div>
                <label class="font-medium">Ciudad: </label>
                {{ datos?.cityName }}
              </div>
              <div>
                <label class="font-medium">Barrio: </label>
                {{ datos?.neighborhood }}
              </div>
              <div>
                <label class="font-medium">Ubicación: </label>
                {{ datos?.currentLocation }}
              </div>
              <div>
                <label class="font-medium">Código de servicio: </label>
                {{ datos?.code }}
              </div>
              <div>
                <label class="font-medium">Estado del servicio: </label>
                {{ datos?.statusName?.toUpperCase() }}
              </div>
              <hr class="mt-2" />
              <div class="mt-2">
                <label class="font-medium">Tipo de servicio: </label>
                {{
                  datos?.serviceId
                    ? 'Taller'
                    : datos?.deliveryType == 1
                    ? 'Mecánico'
                    : 'Grúa'
                }}
              </div>
              <div>
                <label class="font-medium">Hora del servicio: </label>
                {{ datos?.serviceHour }}
              </div>
              <div>
                <label class="font-medium">Taller: </label>
                {{ datos?.garageName }}
              </div>
              <div>
                <label class="font-medium">Valor servicio: </label>
                {{ datos?.serviceValue }}
              </div>
              <div>
                <label class="font-medium">Confirmado: </label>
                {{ datos?.isConfirmated ? 'SI' : 'NO' }}
              </div>
              <hr class="mt-2" />
              <div class="mt-2 text-gray-600 text-xs">
                Datos adicionales
              </div>
              <div>
                <label class="font-medium">Referencia de pago: </label>
                {{ datos?.paymentReference }}
              </div>
              <div>
                <label class="font-medium">Domicilio Pagado: </label>
                {{ datos?.isPaid ? 'SI' : 'NO' }}
              </div>
              <div>
                <label class="font-medium">Marca del vehículo: </label>
                {{ datos?.carBrand }}
              </div>
              <div>
                <label class="font-medium">Modelo del vehículo: </label>
                {{ datos?.carModel }}
              </div>
              <div>
                <label class="font-medium">Kilometraje del vehículo: </label>
                {{ datos?.mileage }}
              </div>
              <div>
                <label class="font-medium">Descripción del usuario: </label>
                {{ datos?.description }}
              </div>
            </div>
            <label for="obs" class="form-label font-medium mt-2"
              >Observaciones</label
            >
            <textarea
              id="obs"
              name="Observaciones"
              class="form-control"
              cols="30"
              rows="5"
              :ref="'servicio' + i"
              :value="datos?.observaciones"
            ></textarea>
            <button
              class="btn btn-outline-secondary py-1 px-2"
              @click="editarFlujo(datos, i)"
            >
              <Edit2Icon class="w-4 h-4 mr-2" /> Actualizar
              <LoadingIcon
                v-if="loading"
                icon="oval"
                color="blue"
                class="w-4 h-4 ml-2"
              />
            </button>
          </div>
        </div>
      </div>
      <!-- END: Users Layout -->
      <!-- BEGIN: Pagination -->
      <!-- <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 form-select box mt-3 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div> -->
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
/* import feather from 'feather-icons'
import Tabulator from 'tabulator-tables' */

export default defineComponent({
  data() {
    return {
      servicios: [],
      domicilios: [],
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'getHistoriesOfServices',
      'agregarObservacion',
      'deleteDomicilio',
      'deleteServicio'
    ]), // Esto llama al metodo en el store que trae los usuarios
    async initTabulator() {
      const doc = await this.gettersServiciosStore.collectionGroup('servicios')
      doc.onSnapshot(
        docSnapshot => {
          const changes = docSnapshot.docChanges()
          this.servicios = []
          changes.forEach(docChange => {
            const newDoc = docChange.doc
            this.servicios.push({
              id: newDoc.id,
              tipo: 'Taller',
              ...newDoc.data()
            })
          })
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
      const docDomicilios = await this.gettersServiciosStore.collectionGroup(
        'domicilios'
      )
      docDomicilios.onSnapshot(
        docSnapshot => {
          const changes = docSnapshot.docChanges()
          this.domicilios = []
          changes.forEach(docChange => {
            const newDoc = docChange.doc
            this.domicilios.push({
              id: newDoc.id,
              tipo: 'Domicilio',
              ...newDoc.data()
            })
          })
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
      /*       this.servicios = this.gettersServiciosStore.map(item => {
        return {
          ...item,
        }
      }) */
      /*       const docDom = await this.gettersServiciosStore
      docDom.onSnapshot(
        docSnapshot => {
          const changes = docSnapshot.docChanges()
          changes.forEach(docChange => {
            const newDoc = docChange.doc
            console.log('domicilios', newDoc.data())
            this.servicios.push({
              id: newDoc.id,
              tipo: 'Domicilio',
              ...newDoc.data()
            })
          })
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      ) */
      /*       this.domicilios = this.gettersDomiciliosStore.filter(item => {
        return item.estado != null
      })
      this.domicilios = this.domicilios.map(item => {
        return {
          ...item,
          tipo: 'Domicilio'
        }
      }) */
    },
    async editarFlujo(data, i) {
      this.loading = true
      const textarea = this.$refs['servicio' + i].value
      this.agregarObservacion({
        ...data,
        observaciones: textarea
      })
      this.loading = false
      await this.getServiciosStore()
      this.initTabulator()
    },
    definirColor(estado) {
      switch (estado) {
        case 'CREADO':
          return '#ffff006b'
        case 'TOMADO':
          return '#00800038'
        case 'CANCELADO':
          return '#ff00002e'
      }
    },
    async eliminar(datos) {
      console.log(datos)
      if (datos.tipo == 'Domicilio') {
        this.deleteDomicilio(datos)
      } else if (datos.tipo == 'Taller') {
        this.deleteServicio(datos)
      }
      await this.initTabulator()
    }
  },
  computed: {
    ...mapGetters(['getterHistoryServices'])
  },
  async mounted() {
    await this.getHistoriesOfServices()
    this.servicios = this.getterHistoryServices.sort(
      (a, b) => new Date(b.readDate) - new Date(a.readDate)
    )
  }
})
</script>
