<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Mecánicos</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="showModal()">
          Nuevo Mecánico
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <input
              @keydown.enter.prevent
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Buscar nombre..."
              v-model="buscar"
            />
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div
      id="modal-mecanicos"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ editarFlag ? 'Editar' : 'Nuevo' }} mecánico
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <form>
            <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-1" class="form-label">Nombres *</label>
                <input
                  id="modal-form-1"
                  type="text"
                  class="form-control"
                  placeholder="nombres"
                  v-model="nombres"
                />
                <div v-if="v$.nombres.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-2" class="form-label">Email *</label>
                <input
                  id="modal-form-2"
                  type="email"
                  class="form-control"
                  placeholder="email@mail.com"
                  v-model="email"
                  :disabled="editarFlag"
                />
                <div v-if="v$.email.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="cel" class="form-label">Celular *</label>
                <input
                  id="cel"
                  type="number"
                  class="form-control"
                  placeholder="Celular"
                  v-model="celular"
                />
                <div v-if="v$.celular.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-cedula" class="form-label">Cédula *</label>
                <input
                  id="modal-cedula"
                  type="text"
                  class="form-control"
                  placeholder="Cedula"
                  v-model="cedula"
                />
                <div v-if="v$.cedula.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="dir" class="form-label">Dirección *</label>
                <input
                  id="dir"
                  type="text"
                  class="form-control"
                  placeholder="Direccion"
                  v-model="direccion"
                />
                <div v-if="v$.direccion.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="ciudad" class="form-label">Ciudad *</label>
                <div v-if="!editarFlag">
                  <TomSelect id="c" v-model="ciudad" class="w-full">
                    <option selected>Seleccione...</option>
                    <option
                      v-for="item in cities"
                      :key="item.cityId"
                      :value="item.cityId"
                    >
                      {{ item.description }}
                    </option>
                  </TomSelect>
                </div>
                <div v-else class="flex">
                  <template
                    class="w-full"
                    :style="{ display: !editCity ? 'block' : 'none' }"
                  >
                    <input
                      id="city"
                      type="text"
                      class="form-control"
                      disabled
                      v-model="cityName"
                    />
                  </template>
                  <template
                    class="w-full"
                    :style="{ display: editCity ? 'block' : 'none' }"
                  >
                    <TomSelect id="ciudades" v-model="ciudad" class="w-full">
                      <option selected>Seleccione...</option>
                      <option
                        v-for="item in cities"
                        :key="item.cityId"
                        :value="item.cityId"
                      >
                        {{ item.description }}
                      </option>
                    </TomSelect>
                  </template>
                  <button
                    type="button"
                    class="btn shadow-md ml-2"
                    :class="[editCity ? 'btn-danger' : 'btn-primary']"
                    @click="toggleEditCity"
                    aria-label="Editar"
                  >
                    <span v-if="editCity">Cancelar</span>
                    <span v-else>Editar</span>
                  </button>
                </div>
                <div v-if="v$.ciudad.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="marcas" class="form-label">Marcas *</label>
                <input
                  id="marcas"
                  type="text"
                  class="form-control"
                  placeholder="marcas"
                  v-model="marcas"
                />
                <div v-if="v$.marcas.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="marcas" class="form-label">Servicios *</label>
                <input
                  id="services"
                  type="text"
                  class="form-control"
                  placeholder="servicios"
                  v-model="services"
                />
                <div v-if="v$.services.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="banco" class="form-label">Banco *</label>
                <input
                  id="banco"
                  type="text"
                  class="form-control"
                  placeholder="Banco"
                  v-model="banco"
                />
                <div v-if="v$.banco.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="cuenta" class="form-label">Cuenta bancaria *</label>
                <input
                  id="cuenta"
                  type="text"
                  class="form-control"
                  placeholder="Cuenta bancaria"
                  v-model="cuentabancaria"
                />
                <div v-if="v$.cuentabancaria.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label"
                  >Contraseña</label
                >
                <input
                  id="modal-form-4"
                  type="password"
                  class="form-control"
                  placeholder="Contraseña"
                  v-model="password"
                />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <div class="mt-3">
                  <label>Técnico</label>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      v-model="tecnico"
                      class="form-check-switch"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <div class="mt-3">
                  <label>Transporte</label>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      v-model="transporte"
                      class="form-check-switch"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <div class="mt-3">
                  <label>Herramientas</label>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      v-model="herramientas"
                      class="form-check-switch"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <div class="mt-3">
                  <label>Disponible</label>
                  <div class="mt-2">
                    <input
                      type="checkbox"
                      v-model="isAvailable"
                      class="form-check-switch"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              @click="editarFlag ? editarMecanico() : crearMecanico()"
            >
              {{ editarFlag ? 'Editar' : 'Crear' }}
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Está seguro?</div>
              <div class="text-gray-600 mt-2">
                Eliminar este registro? <br />Esto será permanente
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="borrarMecanico()"
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      mecanicos: [],
      oldMecanicos: [],
      editarFlag: false,
      nombres: '',
      email: '',
      celular: '',
      cedula: '',
      direccion: '',
      ciudad: '',
      marcas: '',
      services: '',
      banco: '',
      cuentabancaria: '',
      tecnico: '',
      transporte: '',
      herramientas: '',
      password: '',
      mecanicoSelected: {},
      parametros: {},
      buscar: '',
      isAvailable: false,
      cities: [],
      cityName: '',
      editCity: false
    }
  },
  validations() {
    return {
      nombres: { required },
      email: { required },
      celular: { required },
      cedula: { required },
      direccion: { required },
      ciudad: { required },
      marcas: { required },
      services: { required },
      banco: { required },
      cuentabancaria: { required }
    }
  },
  methods: {
    toggleEditCity() {
      this.editCity = !this.editCity
    },
    initTabulator(data) {
      const self = this
      this.tabulator = new Tabulator(this.$refs.tableRef, {
        responsiveLayout: 'collapse',
        responsiveLayoutCollapseStartOpen: false,
        layout: 'fitColumns',
        pagination: 'local',
        printAsHtml: true,
        printStyled: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 100],
        placeholder: 'No se encontraron registros',
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        },
        data: data,
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'IMAGEN',
            minWidth: 200,
            field: 'urlImagen',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return (
                cell.getData().urlImagen &&
                `<div class="w-12 h-12 image-fit">
                  <img
                    alt="Logo"
                    class="rounded-full"
                    src="${cell.getData().urlImagen}"
                  />
                </div>`
              )
            }
          },
          {
            title: 'Nombres',
            minWidth: 200,
            responsive: 0,
            field: 'userName',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'EMAIL',
            minWidth: 200,
            field: 'email',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CELULAR',
            minWidth: 200,
            field: 'cellphone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TIPO DE IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identificationName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identification',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'DIRECCIÓN',
            minWidth: 200,
            field: 'address',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CIUDAD',
            minWidth: 200,
            field: 'cityName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'MARCAS',
            minWidth: 200,
            field: 'brands',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'SERVICIOS',
            minWidth: 200,
            field: 'services',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'VALOR GANADO',
            minWidth: 200,
            field: 'accumulatedValue',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          },
          {
            title: 'VALOR GIRADO',
            minWidth: 200,
            field: 'paidValue',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          },
          {
            title: 'BANCO',
            minWidth: 200,
            field: 'bank',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CUENTA BANCARIA',
            minWidth: 200,
            field: 'bankAccount',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TÉCNICO',
            minWidth: 200,
            field: 'isTechnicalMechanic',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isTechnicalMechanic
                  ? 'text-theme-10'
                  : 'text-theme-24'
              }">
                ${cell.getData().isTechnicalMechanic ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'HERRAMIENTAS',
            minWidth: 200,
            field: 'haveTools',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().haveTools ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().haveTools ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'TRANSPORTE',
            minWidth: 200,
            field: 'haveTransport',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().haveTransport ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().haveTransport ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'DISPONIBLE',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isAvailable ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isAvailable ? 'Disponible' : 'No disponible'}
              </div>`
            }
          },
          {
            title: 'SESION',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isLogin ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isLogin ? 'Activo' : 'Inactivo'}
              </div>`
            }
          },
          {
            title: '',
            minWidth: 200,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" id="edit">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Editar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.showEditModal(cell._cell.row.data)
            }
          },
          {
            title: '',
            minWidth: 200,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" >
                  <i data-feather="trash" class="w-4 h-4 mr-1"></i> Eliminar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.mecanicoSelected = cell._cell.row.data
              self.showAlertDeleteMecanic()
            }
          },
          {
            title: 'INICIO CON GOOGLE',
            minWidth: 200,
            field: 'haveTransport',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isLoginGoogle ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isLoginGoogle ? 'SI' : 'NO'}
              </div>`
            }
          }
        ]
      })
    },
    showModal() {
      this.v$.$reset()
      this.editarFlag = false
      this.mecanicoSelected = {}
      this.email = ''
      this.password = ''
      this.nombres = ''
      this.cedula = ''
      this.celular = ''
      this.direccion = ''
      this.ciudad = ''
      this.tecnico = false
      this.marcas = ''
      this.services = ''
      this.transporte = false
      this.herramientas = false
      this.cuentabancaria = ''
      this.isAvailable = ''
      this.banco = ''
      this.cityName = ''
      this.editCity = false
      cash('#modal-mecanicos').modal('show')
    },
    showEditModal(data) {
      this.editarFlag = true
      this.mecanicoSelected = data
      this.email = data.email
      this.password = data.password
      this.nombres = data.userName
      this.cedula = data.identification
      this.celular = data.cellphone
      this.direccion = data.address
      this.ciudad = data.cityId
      this.tecnico = data.isTechnicalMechanic == 1
      this.marcas = data.brands
      this.services = data.services
      this.transporte = data.haveTransport == 1
      this.herramientas = data.haveTools == 1
      this.cuentabancaria = data.bankAccount
      this.isAvailable = data.isAvailable
      this.banco = data.bank
      this.cityName = data.cityName
      this.editCity = false
      cash('#modal-mecanicos').modal('show')
    },
    async crearMecanico() {
      const result = await this.v$.$validate()
      if (result) {
        const data = {
          email: this.email,
          password: this.password,
          nombres: this.nombres,
          cedula: this.cedula,
          celular: this.celular,
          direccion: this.direccion,
          ciudad: this.ciudad,
          esMecanicoTecnico: this.tecnico,
          marcas: this.marcas,
          services: this.services,
          tieneTransporte: this.transporte,
          tieneHerramientas: this.herramientas,
          cuentaBancaria: this.cuentabancaria,
          banco: this.banco,
          available: this.isAvailable
        }
        const response = await this.createMecanico(data)
        if (response) {
          Toastify({
            text: 'Información guardada correctamente.',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            className: 'toastify-content'
          }).showToast()
        } else {
          Toastify({
            text: 'Lo sentimos la informacion no pudo ser registrada.',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            className: 'toastify-content'
          }).showToast()
        }
        this.cancelModal()
        this.getData()
      }
    },
    async editarMecanico() {
      const data = {
        ...this.mecanicoSelected,
        email: this.email,
        password: this.password,
        nombres: this.nombres,
        cedula: this.cedula,
        celular: this.celular,
        direccion: this.direccion,
        ciudad: this.ciudad,
        esMecanicoTecnico: this.tecnico,
        marcas: this.marcas,
        services: this.services,
        tieneTransporte: this.transporte,
        tieneHerramientas: this.herramientas,
        cuentaBancaria: this.cuentabancaria,
        banco: this.banco,
        available: this.isAvailable
      }
      const response = await this.updateMecanico(data)
      if (response) {
        Toastify({
          text: 'Información actualizada correctamente.',
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top', // `top` or `bottom`
          position: 'right', // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          className: 'toastify-content'
        }).showToast()
      } else {
        Toastify({
          text: 'Lo sentimos la informacion no pudo ser actualizada.',
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top', // `top` or `bottom`
          position: 'right', // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          className: 'toastify-content'
        }).showToast()
      }
      this.cancelModal()
      this.getData()
    },
    showAlertDeleteMecanic() {
      cash('#delete-modal').modal('show')
    },
    async borrarMecanico() {
      cash('#delete-modal').modal('hide')
      await this.deleteUsuario(this.mecanicoSelected.userId)
      this.mecanicoSelected = {}
      this.getData()
    },
    cancelModal() {
      this.email = ''
      this.password = ''
      this.nombres = ''
      this.cedula = ''
      this.celular = ''
      this.direccion = ''
      this.ciudad = ''
      this.tenico = ''
      this.marcas = ''
      this.transporte = ''
      this.herramientas = ''
      this.cuentabancaria = ''
      this.banco = ''
      this.isAvailable = false
      this.tecnico = false
      cash('#modal-mecanicos').modal('hide')
    },
    async getData() {
      await this.getMecanicosStore()
      this.mecanicos = this.gettersMecanicosStore.reverse()
      this.oldMecanicos = this.mecanicos
      this.initTabulator(this.mecanicos)
    },
    ...mapActions([
      'getMecanicosStore',
      'createMecanico',
      'updateMecanico',
      'deleteUsuario',
      'getCitiesParameters'
    ]) // listo los metodos en el store que trae los usuarios
  },
  computed: {
    ...mapGetters(['gettersMecanicosStore', 'getterCitiesParameters'])
  },
  watch: {
    buscar(newValue, oldValue) {
      let mecanicosTemp = []
      if (newValue) {
        mecanicosTemp = this.mecanicos.filter(mecanico =>
          mecanico.nombre.toLowerCase().includes(this.buscar.toLowerCase())
        )
      } else if (newValue == '') {
        mecanicosTemp = this.oldMecanicos
      }
      this.tabulator.replaceData(mecanicosTemp)
    }
  },
  async mounted() {
    await this.getCitiesParameters()
    this.cities = this.getterCitiesParameters
    this.getData()
  }
})
</script>
