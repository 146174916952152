import { signInUser } from '../data/services'

const state = () => {
  return {
    token: ''
  }
}

// mutations
const mutations = {
  setToken(state, data) {
    state.token = data
  }
}

// getters
const getters = {
  getterGetToken(state) {
    const localToken = localStorage.getItem('token')
    return state.token ?? localToken
  }
}

// actions
const actions = {
  removeTokenUser({ commit }) {
    localStorage.removeItem('token')
    commit('setToken', '')
  },
  async singInStore({ commit }, data) {
    const response = await signInUser(data)
    if (!response?.success) {
      return {
        ...response
      }
    }
    if (response?.user?.userName.trim().toLowerCase() === 'super admin') {
      commit('setToken', response?.token)
      return response
    } else {
      return {
        success: false,
        data: 'User don´t allowed'
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
