<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <div class="w-full">
        <h2 class="text-lg font-medium mr-auto mt-4">Movimientos de pagos</h2>
        <span class="text-sm mr-auto">Aquí podrá ver los movimientos de los pagos que sea hacen a los mecánicos y grúas.</span>
      </div>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="showModal()">
          Nuevo Pago
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div
      id="modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      data-backdrop="static">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              Nuevo pago
            </h2>
          </div>
          <form class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label">Usuario *</label>
              <TomSelect id="typePay" v-model="nameUser" class="w-full">
                <option selected>Seleccione...</option>
                <option v-for="user in userList" :key="user.id" :value="user.id" >{{user.name }}</option>>
              </TomSelect>
              <div v-if="v$.nameUser.$error">
                <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="typePay" class="form-label">Tipo de pago *</label>
              <TomSelect id="typePay" v-model="typePay" class="w-full">
                <option selected>Seleccione...</option>
                <option :key="1" :value="1" >Efectivo</option>>
                <option :key="2" :value="2" >Transferencia</option>>
              </TomSelect>
              <div v-if="v$.typePay.$error">
                <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label">Fecha *</label>
              <input
                id="modal-form-1"
                type="date"
                class="form-control"
                placeholder="Tipo de pago"
                v-model="datePay"
              />
              <div v-if="v$.datePay.$error">
                <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label">Código de referencia *</label>
              <input
                id="modal-form-1"
                type="text"
                class="form-control"
                placeholder="Código de referencia"
                v-model="referenceCode"
              />
              <div v-if="v$.referenceCode.$error">
                <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="modal-form-1" class="form-label">Valor *</label>
              <input
                id="modal-form-1"
                type="number"
                class="form-control"
                placeholder="Valor"
                v-model="valuePay"
              />
              <div v-if="v$.valuePay.$error">
                <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
              </div>
            </div>
          </form>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              @click="createPay()"
            >
              Crear
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL DELETE REGISTER -->
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-22 mx-auto mt-3" />
              <div class="text-3xl mt-5">¡Atención!</div>
              <div class="text-gray-600 mt-2">
                La eliminación de este registro sera de manera permanente.
                <br />
                ¿Está seguro?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="deletePay()"
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
///
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      paySelected: '',
      userList: [],
      carteras: [],
      nameUser: '',
      typePay: '',
      datePay: '',
      referenceCode: '',
      valuePay: ''
    }
  },
  validations () {
    return {
      nameUser: { required },
      typePay: { required },
      datePay: { required },
      referenceCode: { required },
      valuePay: { required }
    }
  },
  methods: {
    initTabulator() {
      const self = this
      this.carteras = this.gettersPaymentsStore?.reverse()
      this.tabulator = new Tabulator(this.$refs.tableRef, {
        layout: 'fitColumns',
        pagination: 'local',
        printAsHtml: true,
        printStyled: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 100],
        placeholder: 'No se encontraron registros',
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        },
        data: this.carteras,
        columns: [
          /*           {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },
 */
          // For HTML table
          {
            title: 'ID',
            minWidth: 200,
            responsive: 0,
            field: 'id',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'NOMBRES',
            minWidth: 200,
            responsive: 0,
            field: 'userName',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TIPO DE PAGO',
            minWidth: 200,
            field: 'paymentTypeName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'FECHA',
            minWidth: 200,
            field: 'date',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CÓDIGO DE REFERENCIA',
            minWidth: 200,
            field: 'referenceCode',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'VALOR',
            minWidth: 200,
            field: 'value',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          },
          {
            title: '',
            minWidth: 200,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" >
                  <i data-feather="trash" class="w-4 h-4 mr-1"></i> Eliminar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.messageDelete(cell._cell.row.data)
            }
          }
        ]
      })
    },
    showModal() {
      this.v$.$reset()
      this.nameUser = ''
      this.typePay = ''
      this.datePay = ''
      this.referenceCode = ''
      this.valuePay = ''
      cash('#modal').modal('show')
    },
    cancelModal() {
      this.v$.$reset()
      this.nameUser = ''
      this.typePay = ''
      this.datePay = ''
      this.referenceCode = ''
      this.valuePay = ''
      cash('#modal').modal('hide')
    },
    async createPay() {
      const result = await this.v$.$validate()
      if (!result) return
      const body = {
        value: this.valuePay,
        paymentUserId: this.nameUser,
        paymentTypeId: this.typePay,
        date: this.datePay,
        referenceCode: this.referenceCode
      }
      const response = this.createPaymentStore(body)
      console.log('🚀 ~ createPay ~ response:', response)
      await this.getPaymentsStore()
      this.initTabulator()
      cash('#modal').modal('hide')
    },
    messageDelete(data) {
      this.paySelected = data
      cash('#delete-modal').modal('show')
    },
    async deletePay () {
      if (!this.paySelected) return
      const response = await this.deletePayment(this.paySelected.id)
      console.log('🚀 ~ deletePay ~ response:', response)
      await this.getPaymentsStore()
      this.initTabulator()
      cash('#delete-modal').modal('hide')
    },
    async joinUsers () {
      await this.getGruasStore()
      const userCranes = this.gettersGruasStore.map((crane) => { return { name: crane.userName, id: crane.userId } })
      await this.getMecanicosStore()
      const userMechanics = this.gettersMecanicosStore.map((mechanic) => { return { name: mechanic.userName, id: mechanic.userId } })
      this.userList = userCranes.concat(userMechanics)
    },
    ...mapActions([
      'getPaymentsStore',
      'createPaymentStore',
      'getGruasStore',
      'getMecanicosStore',
      'deletePayment'
    ]) // Esto llama al metodo en el store que trae los usuarios
  },
  computed: {
    ...mapGetters([
      'gettersPaymentsStore',
      'gettersMecanicosStore',
      'gettersGruasStore'
    ])
  },
  async mounted() {
    await this.getPaymentsStore()
    this.joinUsers()
    this.initTabulator()
  }
})
</script>
