import { createCrane, createGarage, createMechanic, createPayment, createUser, deletePayment, deleteUser, getCityList, getParameters, getPayments, getUsers, getWallets, historyServices, identificationsTypes, updateCrane, updateGarage, updateMechanic, updateParameters, updateUser } from '../data/services'

const state = () => {
  return {
    historiesServices: [],
    usuarios: [],
    talleres: [],
    mecanicos: [],
    gruas: [],
    carteras: [],
    domicilios: [],
    servicios: [],
    parameters: {},
    citiesParameters: [],
    payments: [],
    identificationsTypes: []
  }
}
// mutations
const mutations = {
  setHistoryServices(state, value) {
    state.historiesServices = value
  },
  setUsuarios(state, value) {
    state.usuarios = value
  },
  setTalleres(state, value) {
    state.talleres = value
  },
  setMecanicos(state, value) {
    state.mecanicos = value
  },
  setGruas(state, value) {
    state.gruas = value
  },
  setCarteras(state, value) {
    state.carteras = value
  },
  setDomicilios(state, value) {
    state.domicilios = value
  },
  setServicios(state, value) {
    state.servicios = value
  },
  setConfiguracion(state, value) {
    state.parameters = value
  },
  setFirebase(state, value) {
  },
  setPayments(state, value) {
    state.payments = value
  },
  setCities(state, value) {
    state.citiesParameters = value
  },
  setIdentificationTypes(state, value) {
    state.identificationsTypes = value
  }
  /*   [types.SET_DARK_MODE](state, { darkMode }) {
      state.darkMode = darkMode
    } */
}
// actions
const actions = {
  async getHistoriesOfServices({ commit }) {
    const list = await historyServices()
    commit('setHistoryServices', list)
  },
  async getUsuariosStore({ commit }) {
    let list = []
    list = await getUsers(1)
    commit('setUsuarios', list)
  },
  async getPaymentsStore({ commit }) {
    let list = []
    list = await getPayments()
    commit('setPayments', list)
  },
  async createPaymentStore({ commit }, data) {
    const body = {
      value: data.value,
      paymentUserId: data.paymentUserId,
      paymentTypeId: data.paymentTypeId,
      date: data.date,
      referenceCode: data.referenceCode
    }
    const response = await createPayment(body)
    return response
  },
  async deletePayment({ commit }, id) {
    const response = await deletePayment(id)
    return response
  },
  async setUsuariosStore({ commit }, data) {
    // Crear usuario
    const response = await createUser(data)
    return response
  },
  async deleteUsuario({ commit }, id) {
    const response = await deleteUser(id)
    return response
  },
  async setUpdateUser({ commit }, data) {
    const response = await updateUser(data)
    return response
  },
  async getTalleresStore({ commit }) {
    let list = []
    list = await getUsers(2)
    commit('setTalleres', list)
  },
  async deleteTalleres({ commit }, data) {
  },
  async createTaller({ commit }, data) {
    try {
      const body = {
        userName: data?.userName,
        email: data?.email,
        cellphone: data?.phone1,
        phone2: data?.phone2,
        userTypeId: 2,
        urlImage: '',
        fcmToken: '',
        isLogin: 0,
        password: data?.password,
        bank: data?.bank,
        bankAccount: data?.bankAccount,
        cityId: data?.cityId,
        address: data?.address,
        latitud: data?.latitud,
        longitud: data?.longitud,
        brands: data?.brands,
        services: data?.services,
        identification: data?.identification,
        identificationTypeId: 4,
        owner: data?.owner,
        isLoginGoogle: false,
        uidGoogle: ''
      }
      const response = await createGarage(body)
      return response
    } catch (error) {
      throw new Error(error)
    }
  },
  async updateTaller({ commit }, data) {
    try {
      const body = {
        userId: data?.userId,
        identification: data?.identification,
        email: data?.email,
        password: data?.password,
        userName: data?.userName,
        owner: data?.owner,
        cellphone: data?.cellphone,
        phone2: data?.phone2,
        address: data?.address,
        cityId: data?.cityId,
        services: data?.services,
        brands: data?.brands,
        bankAccount: data?.bankAccount,
        bank: data?.bank,
        latitud: data?.latitud,
        longitud: data?.longitud,
        fcmToken: data?.fcmToken,
        isLogin: data?.isLogin,
        urlImage: data?.urlImagen ?? '',
        isLoginGoogle: data?.isLoginGoogle,
        uidGoogle: data?.uidGoogle ?? '',
        identificationTypeId: 4,
        userTypeId: 2,
        isAvailable: true
      }
      const response = await updateGarage(body)
      return response
    } catch (error) {
      console.log('🚀 ~ updateTaller ~ error:', error)
    }
  },
  async getMecanicosStore({ commit }) {
    let list = []
    list = await getUsers(3)
    commit('setMecanicos', list)
  },
  async getGruasStore({ commit }) {
    const list = await getUsers(4)
    commit('setGruas', list)
  },
  async createMecanico({ commit }, data) {
    try {
      const body = {
        userName: data?.nombres,
        email: data?.email,
        cellphone: data?.celular,
        password: data?.password,
        identification: data?.cedula,
        bank: data?.banco,
        bankAccount: data?.cuentaBancaria,
        brands: data?.marcas,
        services: data?.services,
        haveTools: data?.tieneHerramientas,
        haveTransport: data?.tieneTransporte,
        isTechnicalMechanic: data?.esMecanicoTecnico,
        cityId: data?.ciudad,
        address: data?.direccion,
        isAvailable: data?.available,
        userTypeId: 3,
        urlImage: '',
        fcmToken: '',
        isLogin: 0,
        identificationTypeId: 1,
        identificationName: 'Cedula',
        identificationAbr: 'CC',
        latitud: '',
        longitud: '',
        isLoginGoogle: false,
        uidGoogle: ''
      }
      const response = await createMechanic(body)
      return response
    } catch (error) {
      throw new Error(error)
    }
  },
  async updateMecanico({ commit }, data) {
    try {
      const body = {
        userId: data?.userId,
        userName: data?.nombres,
        email: data?.email,
        cellphone: data?.celular,
        password: data?.password,
        identification: data?.cedula,
        bank: data?.banco,
        bankAccount: data?.cuentaBancaria,
        brands: data?.marcas,
        services: data?.services,
        haveTools: data?.tieneHerramientas,
        haveTransport: data?.tieneTransporte,
        isTechnicalMechanic: data?.esMecanicoTecnico,
        cityId: data?.ciudad,
        address: data?.direccion,
        isAvailable: data?.available,
        userTypeId: data?.userTypeId,
        urlImage: data?.urlImage,
        fcmToken: data?.fcmToken,
        isLogin: data?.isLogin,
        identificationTypeId: data?.identificationTypeId,
        identificationName: data?.identificationName,
        identificationAbr: data?.identificationAbr,
        latitud: data?.latitud,
        longitud: data?.longitud,
        isLoginGoogle: data?.isLoginGoogle,
        uidGoogle: data?.uidGoogle
      }
      const response = await updateMechanic(body)
      return response
    } catch (error) {
      throw new Error(error)
    }
  },
  async deleteMecanico({ commit }, data) {
  },
  async getCarterasStore({ commit }) {
    let list = []
    list = await getWallets()
    commit('setCarteras', list)
  },
  async getDomiciliosStore({ commit }) {
  },
  async getServiciosStore({ commit }) {
  },
  deleteServicio({ commit }, datos) {
  },
  async agregarObservacion({ commit }, data) {
  },
  async getConfiguracionStore({ commit }) {
    const parameters = await getParameters()
    const parameterObject = {}
    for (const element of parameters) {
      parameterObject[element.keyName] = element.value
    }
    commit('setConfiguracion', parameterObject)
  },
  async updateConfiguracion({ commit }, data) {
    const response = await updateParameters(data)
    return response
  },
  async getCitiesParameters({ commit }) {
    let list = []
    list = await getCityList()
    commit('setCities', list)
  },
  async getIdentificationsTypes({ commit }) {
    const list = await identificationsTypes()
    commit('setIdentificationTypes', list)
  },
  async createCrane({ commit }, data) {
    const body = {
      userName: data?.userName,
      email: data?.email,
      cellphone: data?.cellphone,
      password: data?.password,
      bank: data?.bank,
      bankAccount: data?.bankAccount,
      cityId: data?.cityId,
      address: data?.address,
      identification: data?.identification,
      identificationTypeId: data?.identificationTypeId,
      owner: data?.owner,
      isAvailable: data?.isAvailable,
      latitud: '',
      longitud: '',
      brands: '',
      services: '',
      phone2: '',
      userTypeId: 4,
      urlImage: '',
      fcmToken: '',
      isLogin: 0,
      isLoginGoogle: false,
      uidGoogle: ''
    }
    const response = await createCrane(body)
    return response
  },
  async updateCrane({ commit }, data) {
    const body = {
      userId: data?.userId,
      userName: data?.userName,
      email: data?.email,
      cellphone: data?.cellphone,
      password: data?.password,
      bank: data?.bank,
      bankAccount: data?.bankAccount,
      cityId: data?.cityId,
      address: data?.address,
      identification: data?.identification,
      identificationTypeId: data?.identificationTypeId,
      owner: data?.owner,
      isAvailable: data?.isAvailable,
      latitud: '',
      longitud: '',
      brands: '',
      services: '',
      phone2: '',
      userTypeId: 4,
      urlImage: '',
      fcmToken: '',
      isLogin: 0,
      isLoginGoogle: false,
      uidGoogle: ''
    }
    const response = await updateCrane(body)
    return response
  }
}
// getters
const getters = {
  getterHistoryServices(state) {
    return state.historiesServices
  },
  gettersUsuariosStore(state) {
    return state.usuarios
  },
  gettersTalleresStore(state) {
    return state.talleres
  },
  gettersMecanicosStore(state) {
    return state.mecanicos
  },
  gettersGruasStore(state) {
    return state.gruas
  },
  gettersCarterasStore(state) {
    return state.carteras
  },
  gettersDomiciliosStore(state) {
    return state.domicilios
  },
  gettersServiciosStore(state) {

  },
  gettersConfiguracionStore(state) {
    return state.parameters
  },
  gettersPaymentsStore(state) {
    return state.payments
  },
  getterCitiesParameters(state) {
    return state.citiesParameters
  },
  getterIdentificationsTypes(state) {
    return state.identificationsTypes
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
