<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Movimientos de carteras</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
///
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'

export default defineComponent({
  data() {
    return {
      carteras: []
    }
  },
  methods: {
    ...mapActions(['getCarterasStore']), // Esto llama al metodo en el store que trae los usuarios
    initTabulator() {
      this.carteras = this.gettersCarterasStore.reverse()
      this.tabulator = new Tabulator(this.$refs.tableRef, {
        layout: 'fitColumns',
        pagination: 'local',
        printAsHtml: true,
        printStyled: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 100],
        placeholder: 'No se encontraron registros',
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        },
        data: this.carteras,
        columns: [
          /*           {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },
 */
          // For HTML table
          {
            title: 'ID SERVICIO',
            minWidth: 200,
            responsive: 0,
            field: 'id',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'FECHA',
            minWidth: 200,
            responsive: 0,
            field: 'dateRegister',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Date(cell.getValue() ?? '').toLocaleDateString()
            }
          },
          {
            title: 'TIPO DE CUENTA',
            minWidth: 200,
            field: 'walletType',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'ESTADO CARTERA',
            minWidth: 200,
            field: 'statusType',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'NOMBRE USUARIO',
            minWidth: 200,
            field: 'userName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TIPO',
            minWidth: 200,
            field: 'userType',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'VALOR',
            minWidth: 200,
            field: 'value',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          }
        ]
      })
    }
  },
  computed: {
    ...mapGetters(['gettersCarterasStore'])
  },
  async mounted() {
    await this.getCarterasStore()
    this.initTabulator()
  }
})
</script>
