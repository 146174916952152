<template>
  <GoogleMapLoader :init="init" />
</template>

<script>
import { defineComponent, computed } from 'vue'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const store = useStore()
    // const darkMode = computed(() => store.state.main.darkMode)
    const init = async initializeMap => {
      // const darkTheme = [
      //   {
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#242f3e'
      //       }
      //     ]
      //   },
      //   {
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#746855'
      //       }
      //     ]
      //   },
      //   {
      //     elementType: 'labels.text.stroke',
      //     stylers: [
      //       {
      //         color: '#242f3e'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'administrative.land_parcel',
      //     elementType: 'labels',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'administrative.land_parcel',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#bdbdbd'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'administrative.locality',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#d59563'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#eeeeee'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi',
      //     elementType: 'labels.text',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#d59563'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi.park',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#263c3f'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi.park',
      //     elementType: 'geometry.fill',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'poi.park',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#6b9a76'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#38414e'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'geometry.stroke',
      //     stylers: [
      //       {
      //         color: '#212a37'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#9ca5b3'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.arterial',
      //     elementType: 'labels',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.arterial',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#757575'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#746855'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'geometry.stroke',
      //     stylers: [
      //       {
      //         color: '#1f2835'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'labels',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.highway',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#f3d19c'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.local',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'road.local',
      //     elementType: 'labels',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#2f3948'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit.line',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#e5e5e5'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit.line',
      //     elementType: 'geometry.fill',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit.station',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#eeeeee'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit.station',
      //     elementType: 'geometry.fill',
      //     stylers: [
      //       {
      //         visibility: 'off'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'transit.station',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#d59563'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#17263c'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'geometry.fill',
      //     stylers: [
      //       {
      //         color: '#171f29'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'labels.text.fill',
      //     stylers: [
      //       {
      //         color: '#515c6d'
      //       }
      //     ]
      //   },
      //   {
      //     featureType: 'water',
      //     elementType: 'labels.text.stroke',
      //     stylers: [
      //       {
      //         color: '#17263c'
      //       }
      //     ]
      //   }
      // ]
      const lightTheme = [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'road.local',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#e0e3e8'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        }
      ]
      const { google, map } = await initializeMap({
        apiKey: 'AIzaSyD4zbqzFr6Ne5A2BPwxZgoepOeBkVW7P30',
        config(google) {
          return {
            zoom: 6.0,
            styles: lightTheme,
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            streetViewControl: false,
            center: {
              // lat: -6.2425342,
              // lng: 106.8626478
              // Colombia location
              lat: 6.25184,
              lng: -75.56359
            }
          }
        }
      })

      const infoWindow = new google.maps.InfoWindow({
        minWidth: 400,
        maxWidth: 400
      })
      await store.dispatch('getTalleresStore')
      // store.dispatch('main/setDarkMode', !darkMode.value)

      const garages = computed(() => store.state.tumecanicoStore.talleres)

      const locations = garages.value.map(item => {
        return {
          name: item.userName,
          latitude: item.latitud,
          longitude: item.longitud
        }
      })

      const location = locations
      new MarkerClusterer(
        map,
        location.map(function(markerElem) {
          const icon = require('@/assets/images/makerTml.png')
          // if (markerElem.name == 'Taller') {
          //   icon = require('@/assets/images/marker-garage-shop.png')
          // }
          // if (markerElem.name == 'Mecanico') {
          //   icon = require('@/assets/images/mechanic-marker-128.png')
          // }
          // if (markerElem.name == 'Grua') {
          //   icon = require('@/assets/images/tow-truck.png')
          // }
          // if (markerElem.name == 'Cliente') {
          //   icon = require('@/assets/images/car-broken.png')
          // }
          const point = new google.maps.LatLng(
            parseFloat(markerElem.latitude),
            parseFloat(markerElem.longitude)
          )
          const infowincontent = `
            <div class="font-medium">
              ${markerElem.name}
            </div>
            <div class="mt-1 text-gray-600">
              Latitude: ${markerElem.latitude}, Longitude: ${markerElem.longitude}
            </div>`
          const marker = new google.maps.Marker({
            map: map,
            position: point,
            icon: {
              url: icon
            }
          })

          google.maps.event.addListener(marker, 'click', function() {
            infoWindow.setContent(infowincontent)
            google.maps.event.addListener(infoWindow, 'domready', function() {
              cash('.arrow_box')
                .closest('.gm-style-iw-d')
                .removeAttr('style')
              cash('.arrow_box')
                .closest('.gm-style-iw-d')
                .attr('style', 'overflow:visible')
              cash('.arrow_box')
                .closest('.gm-style-iw-d')
                .parent()
                .removeAttr('style')
            })

            infoWindow.open(map, marker)
          })
          return marker
        }),
        {
          styles: [
            {
              width: 51,
              height: 50,
              textColor: 'white',
              url: require('@/assets/images/map-marker-region.png'),
              anchor: [0, 0],
              anchorText: [19, 0],
              fontWeight: 'bold'
            }
          ]
        }
      )

      // const stop = watch(darkMode, () => {
      //   init(initializeMap)
      //   stop()
      // })
    }

    return {
      init
    }
  }
})
</script>
