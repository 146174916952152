<template>
  <div>
    <a
      class="btn btn-primary m-5"
      href="intent://com.deus.mecanico_app/#Intent;scheme=launch;package=com.deus.mecanico_app;end"
      >Open Application</a
    ><br />
    <div class="intro-y box p-5 mt-12 sm:mt-5">
      <ReportMap class="report-maps mt-5 bg-gray-200 rounded-md" />
    </div>
    <VerticalBarChart :info="talleres" />
    <VerticalBarChartDeliveries :info="mecanicos" />
    <VerticalBarChartDeliveries :info="gruas" />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import ReportMap from '../components/report-map/Main.vue'
import VerticalBarChart from '../components/vertical-bar-chart/Main.vue'
import VerticalBarChartDeliveries from '../components/vertical-bar-chart-deliveries/Main.vue'
import { useStore } from '@/store'
export default defineComponent({
  components: {
    ReportMap,
    VerticalBarChart,
    VerticalBarChartDeliveries
  },
  setup() {
    const store = useStore()

    onMounted(async () => {
      await store.dispatch('getTalleresStore')
      await store.dispatch('getMecanicosStore')
      await store.dispatch('getGruasStore')
    })
    const garages = computed(() => store.state.tumecanicoStore.talleres)
    const gruas = computed(() => store.state.tumecanicoStore.gruas)
    const mecanicos = computed(() => store.state.tumecanicoStore.mecanicos)
    return {
      talleres: garages,
      gruas,
      mecanicos
    }
  }
})
</script>

<style></style>
