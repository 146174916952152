<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Talleres</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="showModal()">
          Nuevo taller
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <input
              @keydown.enter.prevent
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Buscar nombre..."
              v-model="buscar"
            />
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <div
      id="modal-talleres"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ editarFlag ? 'Editar' : 'Nuevo' }} taller
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <div class="modal-body">
            <form action="submit" class="grid grid-cols-12 gap-4 gap-y-3">
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-1" class="form-label"
                  >Nombre o razón social *</label
                >
                <input
                  id="modal-form-1"
                  type="text"
                  class="form-control"
                  placeholder="nombre o razón social"
                  v-model="nombres"
                />
                <div v-if="v$.nombres.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-2" class="form-label">Email *</label>
                <input
                  id="modal-form-2"
                  type="email"
                  class="form-control"
                  placeholder="email@mail.com"
                  v-model="email"
                />
                <div v-if="v$.email.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="nit" class="form-label">Nit *</label>
                <input
                  id="nit"
                  type="text"
                  class="form-control"
                  placeholder="Nit"
                  v-model="nit"
                />
                <div v-if="v$.nit.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="cel" class="form-label">Celular *</label>
                <input
                  id="cel"
                  type="number"
                  class="form-control"
                  placeholder="Celular"
                  v-model="celular"
                />
                <div v-if="v$.celular.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="tel1" class="form-label">Teléfono *</label>
                <input
                  id="tel1"
                  type="tel"
                  class="form-control"
                  placeholder="Telefono"
                  v-model="telefono1"
                />
                <div v-if="v$.telefono1.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="dir" class="form-label">Dirección *</label>
                <input
                  id="dir"
                  type="text"
                  class="form-control"
                  placeholder="Direccion"
                  v-model="direccion"
                />
                <div v-if="v$.direccion.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="ciudades" class="form-label">Ciudad *</label>
                <div v-if="!editarFlag">
                  <TomSelect id="c" v-model="ciudad" class="w-full">
                    <option selected>Seleccione...</option>
                    <option
                      v-for="item in cities"
                      :key="item.cityId"
                      :value="item.cityId"
                    >
                      {{ item.description }}
                    </option>
                  </TomSelect>
                </div>
                <div v-else class="flex">
                  <template
                    class="w-full"
                    :style="{ display: !editCity ? 'block' : 'none' }"
                  >
                    <input
                      id="city"
                      type="text"
                      class="form-control"
                      disabled
                      v-model="cityName"
                    />
                  </template>
                  <template
                    class="w-full"
                    :style="{ display: editCity ? 'block' : 'none' }"
                  >
                    <TomSelect id="ciudades" v-model="ciudad" class="w-full">
                      <option selected>Seleccione...</option>
                      <option
                        v-for="item in cities"
                        :key="item.cityId"
                        :value="item.cityId"
                      >
                        {{ item.description }}
                      </option>
                    </TomSelect>
                  </template>
                  <button
                    type="button"
                    class="btn shadow-md ml-2"
                    :class="[editCity ? 'btn-danger' : 'btn-primary']"
                    @click="toggleEditCity"
                    aria-label="Editar"
                  >
                    <span v-if="editCity">Cancelar</span>
                    <span v-else>Editar</span>
                  </button>
                </div>
                <div v-if="v$.ciudad.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="propietario" class="form-label"
                  >Propietario *</label
                >
                <input
                  id="propietario"
                  type="text"
                  class="form-control"
                  placeholder="Propietario"
                  v-model="propietario"
                />
                <div v-if="v$.propietario.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="marcas" class="form-label">Marcas *</label>
                <input
                  id="marcas"
                  type="text"
                  class="form-control"
                  placeholder="marcas"
                  v-model="marcas"
                />
                <div v-if="v$.marcas.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="servicio" class="form-label">Servicios *</label>
                <input
                  id="servicio"
                  type="text"
                  class="form-control"
                  placeholder="servicios"
                  v-model="servicios"
                />
                <div v-if="v$.servicios.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="banco" class="form-label">Banco *</label>
                <input
                  id="banco"
                  type="text"
                  class="form-control"
                  placeholder="Banco"
                  v-model="banco"
                />
                <div v-if="v$.banco.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="cuentabancario" class="form-label"
                  >Cuenta bancaria *</label
                >
                <input
                  id="cuentabancario"
                  type="text"
                  class="form-control"
                  placeholder="Cuenta bancaria"
                  v-model="cuentabancaria"
                />
                <div v-if="v$.cuentabancaria.$error">
                  <span class="mt-3 text-center font-bold text-theme-5"
                    >Campo requerido</span
                  >
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <div class="">
                  <label for="latitud">latitud *</label>
                  <div class="mt-2">
                    <input
                      id="latitud"
                      type="text"
                      v-model="latitud"
                      placeholder="4.7109957140873515"
                      class="form-control"
                    />
                    <div v-if="v$.latitud.$error">
                      <span class="mt-3 text-center font-bold text-theme-5"
                        >Campo requerido</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <div>
                  <label for="herramienta">Longitud *</label>
                  <div class="mt-2">
                    <input
                      id="Longitud"
                      type="text"
                      v-model="longitud"
                      placeholder="-74.07205838301302"
                      class="form-control"
                    />
                    <div v-if="v$.longitud.$error">
                      <span class="mt-3 text-center font-bold text-theme-5"
                        >Campo requerido</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="pass" class="form-label">Contraseña</label>
                <input
                  id="pass"
                  type="password"
                  class="form-control"
                  placeholder="Contraseña"
                  v-model="password"
                />
              </div>
            </form>
          </div>
          <!-- END: Modal Body -->
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              @click="editarFlag ? editarTaller() : crearTaller()"
            >
              {{ editarFlag ? 'Editar' : 'Crear' }}
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Está seguro?</div>
              <div class="text-gray-600 mt-2">
                Eliminar este registro? <br />Esto será permanente
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="borrar()"
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      talleres: [],
      oldTalleres: [],
      editarFlag: false,
      nombres: '',
      email: '',
      celular: '',
      telefono1: '',
      telefono2: '',
      direccion: '',
      ciudad: '',
      nit: '',
      propietario: '',
      marcas: '',
      servicios: '',
      banco: '',
      cuentabancaria: '',
      herramientas: false,
      password: '',
      latitud: '',
      longitud: '',
      tallerSelected: {},
      parametros: {},
      buscar: '',
      cities: [],
      cityName: '',
      editCity: false
    }
  },
  validations() {
    return {
      nombres: { required },
      email: { required },
      celular: { required },
      telefono1: { required },
      ciudad: { required },
      direccion: { required },
      propietario: { required },
      marcas: { required },
      servicios: { required },
      banco: { required },
      cuentabancaria: { required },
      latitud: { required },
      longitud: { required },
      nit: { required }
    }
  },
  methods: {
    toggleEditCity() {
      this.editCity = !this.editCity
    },
    async initTabulator() {
      const self = this
      this.tabulator = new Tabulator(this.$refs.tableRef, {
        responsiveLayout: 'collapse',
        responsiveLayoutCollapseStartOpen: false,
        layout: 'fitColumns',
        pagination: 'local',
        printAsHtml: true,
        printStyled: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 100],
        placeholder: 'No se encontraron registros',
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        },
        data: this.talleres.reverse(),
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },
          // For HTML table
          {
            title: 'IMAGEN',
            minWidth: 200,
            field: 'urlImage',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return (
                cell.getData().urlImagen &&
                `<div class="w-12 h-12 image-fit">
                  <img
                    alt="Logo"
                    class="rounded-full"
                    src="${cell.getData().urlImagen}"
                  />
                </div>`
              )
            }
          },
          {
            title: 'Nombres',
            minWidth: 200,
            responsive: 0,
            field: 'userName',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'EMAIL',
            minWidth: 200,
            field: 'email',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CELULAR',
            minWidth: 200,
            field: 'cellphone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'DIRECCION',
            minWidth: 200,
            field: 'address',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CIUDAD',
            minWidth: 200,
            field: 'cityName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CARTERA',
            minWidth: 200,
            field: 'accumulatedValue',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue() ?? 0)
            }
          },
          {
            title: 'TIPO DE IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identificationName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identification',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'PROPIETARIO',
            minWidth: 200,
            field: 'owner',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'MARCAS',
            minWidth: 200,
            field: 'brands',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'SERVICIOS',
            minWidth: 200,
            field: 'services',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'BANCO',
            minWidth: 200,
            field: 'bank',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CUENTA BANCARIA',
            minWidth: 200,
            field: 'bankAccount',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          // {
          //   title: 'HERRAMIENTAS',
          //   minWidth: 200,
          //   field: 'tieneHerramientas',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     return `<div class="flex items-center lg:justify-center ${
          //       cell.getData().tieneHerramientas
          //         ? 'text-theme-10'
          //         : 'text-theme-24'
          //     }">
          //       ${cell.getData().tieneHerramientas ? 'SI' : 'NO'}
          //     </div>`
          //   }
          // },
          {
            title: 'INICIO DE SESIÓN',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex ${
                cell.getData().isLogin ? 'text-theme-10' : 'text-theme-24'
              }">
              ${cell.getData().isLogin ? 'Activo' : 'Inactivo'}
              </div>`
            }
          },
          {
            title: 'INICIO POR GOOGLE',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex ${
                cell.getData().isLoginGoogle ? 'text-theme-10' : 'text-theme-24'
              }">
              ${cell.getData().isLoginGoogle ? 'Sí' : 'NO'}
              </div>`
            }
          },
          {
            title: 'DISPONIBLE',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex ${
                cell.getData().isAvailable ? 'text-theme-10' : 'text-theme-24'
              }">
              ${cell.getData().isAvailable ? 'Sí' : 'NO'}
              </div>`
            }
          },
          {
            title: 'LATITUD/LONGITUD',
            minWidth: 200,
            field: 'latitud',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `${cell.getData().latitud} / ${cell.getData().longitud}`
            }
          },
          {
            title: '',
            minWidth: 100,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" id="edit">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Editar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.showEditModal(cell._cell.row.data)
            }
          },
          {
            title: '',
            minWidth: 100,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center text-theme-24" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> Eliminar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.tallerSelected = cell._cell.row.data
              self.showAlertDelete()
            }
          }
        ]
      })
    },
    showModal() {
      this.v$.$reset()
      this.editarFlag = false
      this.nombres = ''
      this.email = ''
      this.celular = ''
      this.telefono1 = ''
      this.telefono2 = ''
      this.direccion = ''
      this.ciudad = ''
      this.nit = ''
      this.propietario = ''
      this.marcas = ''
      this.servicios = ''
      this.banco = ''
      this.cuentabancaria = ''
      this.herramientas = ''
      this.password = ''
      cash('#modal-talleres').modal('show')
    },
    showEditModal(data) {
      console.log('🚀 ~ showEditModal ~ data:', data)
      this.tallerSelected = data
      this.editarFlag = true
      this.nombres = data.userName
      this.email = data.email
      this.celular = data.cellphone
      this.telefono1 = data.phone2
      this.direccion = data.address
      this.ciudad = data.cityId
      this.nit = data.identification
      this.propietario = data.owner
      this.marcas = data.brands
      this.servicios = data.services
      this.banco = data.bank
      this.cuentabancaria = data.bankAccount
      this.password = data.password
      this.latitud = data.latitud
      this.longitud = data.longitud
      this.cityName = data.cityName
      this.editCity = false
      cash('#modal-talleres').modal('show')
    },
    cancelModal() {
      cash('#modal-talleres').modal('hide')
    },
    async crearTaller() {
      const result = await this.v$.$validate()
      if (!result) return
      const body = {
        userName: this.nombres,
        email: this.email,
        cellphone: this.celular,
        phone2: this.telefono1,
        password: this.password,
        bank: this.banco,
        bankAccount: this.cuentabancaria,
        cityId: this.ciudad,
        address: this.direccion,
        latitud: this.latitud,
        longitud: this.longitud,
        brands: this.marcas,
        services: this.servicios,
        identification: this.nit,
        owner: this.propietario
      }
      this.createTaller(body)
      this.cancelModal()
      await this.getTalleresStore()
      this.initTabulator()
    },
    async editarTaller() {
      const result = await this.v$.$validate()
      if (!result) return
      const data = {
        userId: this.tallerSelected.userId,
        identification: this.nit,
        email: this.email,
        password: this.password,
        userName: this.nombres,
        owner: this.propietario,
        cellphone: this.celular,
        phone2: this.telefono1,
        address: this.direccion,
        cityId: this.ciudad,
        services: this.servicios,
        brands: this.marcas,
        bankAccount: this.cuentabancaria,
        bank: this.banco,
        latitud: this.latitud,
        longitud: this.longitud,
        fcmToken: this.tallerSelected.fcmToken,
        isLogin: this.tallerSelected.isLogin,
        urlImagen: this.tallerSelected.urlImagen,
        isLoginGoogle: this.tallerSelected.isLoginGoogle,
        uidGoogle: this.tallerSelected.uidGoogle
      }
      await this.updateTaller(data)
      this.cancelModal()
      await this.getTalleresStore()
      this.talleres = await this.gettersTalleresStore
      this.oldTalleres = this.talleres
      this.initTabulator()
    },
    showAlertDelete() {
      cash('#delete-modal').modal('show')
    },
    async borrar() {
      cash('#delete-modal').modal('hide')
      this.deleteUsuario(this.tallerSelected.userId)
      this.tallerSelected = {}
      await this.getTalleresStore()

      this.talleres = await this.gettersTalleresStore
      this.oldTalleres = this.talleres
      this.initTabulator()
    },
    ...mapActions([
      'getTalleresStore',
      'deleteUsuario',
      'createTaller',
      'updateTaller',
      'getCitiesParameters'
    ]) // Esto llama al metodo en el store que trae los usuarios
  },
  computed: {
    ...mapGetters(['gettersTalleresStore', 'getterCitiesParameters'])
  },
  watch: {
    buscar(newValue, oldValue) {
      let talleresTemp = []
      if (newValue) {
        talleresTemp = this.talleres.filter(taller =>
          taller.nombre.toLowerCase().includes(this.buscar.toLowerCase())
        )
      } else if (newValue == '') {
        talleresTemp = this.oldTalleres
      }
      this.tabulator.replaceData(talleresTemp)
    }
  },
  async mounted() {
    await this.getCitiesParameters()
    this.cities = [...this.getterCitiesParameters]
    await this.getTalleresStore()

    this.talleres = await this.gettersTalleresStore
    this.oldTalleres = this.talleres
    this.initTabulator()
  }
})
</script>
