<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Usuarios</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <button class="btn btn-primary shadow-md mr-2" @click="showModal">
          Nuevo usuario
        </button>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ usuarios.length }} registros
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              name="nombre"
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Buscar nombre..."
              @keydown.enter.prevent
              v-model="buscar"
              autocomplete="off"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->
      <div class="intro-y col-span-12 flex absolute float-right w-full">
        <div class="w-full"></div>
        <div class="box mr-2" @click="typeList = true">
          <div
            :class="
              typeList
                ? 'bg-gray-300 flex items-start p-2'
                : 'flex items-start p-2'
            "
          >
            <ListIcon />
          </div>
        </div>
        <div class="box" @click="typeList = false">
          <div
            :class="
              !typeList
                ? 'bg-gray-300 flex items-start p-2'
                : 'flex items-start p-2'
            "
          >
            <GridIcon />
          </div>
        </div>
      </div>
      <div v-show="typeList" class="intro-y col-span-12 box p-5">
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <div
        v-show="!typeList"
        v-for="(item, i) in usuarios"
        :key="i"
        class="intro-y col-span-12 md:col-span-6 lg:col-span-4"
      >
        <div class="box">
          <div class="flex items-start px-5 pt-5">
            <div class="w-full flex flex-col lg:flex-row items-center">
              <div class="w-16 h-16 image-fit">
                <img
                  v-if="item.urlImage != null && item.urlImage != ''"
                  alt="Placeholder"
                  class="rounded-full"
                  :src="item.urlImage"
                />
                <img
                  v-else
                  alt="Placeholder"
                  class="rounded-full"
                  src="@/assets/images/placeholders/200x200.jpg"
                />
              </div>
              <div class="lg:ml-4 text-center lg:text-left mt-3 lg:mt-0">
                <a href="" class="font-medium">{{ item.userName }}</a>
                <div class="text-gray-600 text-xs mt-0.5">
                  {{ item.isLogin ? 'Activo' : 'Inactivo' }}
                </div>
                <div class="text-gray-600 text-xs mt-0.5">
                  Teléfono {{ item.cellphone }}
                </div>
              </div>
            </div>
            <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="javascript:;"
                aria-expanded="false"
              >
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-300"
                />
              </a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a
                    @click="editarUsuario(item)"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                  >
                    <Edit2Icon class="w-4 h-4 mr-2" /> Editar
                  </a>
                  <a
                    @click="mensajeEliminar(item)"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"
                  >
                    <TrashIcon class="w-4 h-4 mr-2" /> Eliminar
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center lg:text-left p-5">
            <div
              class="flex items-center justify-center lg:justify-start text-gray-600 mt-5"
            >
              <MailIcon class="w-3 h-3 mr-2" />
              {{ item.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Modal Content -->
    <div id="modal-usuarios" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- BEGIN: Modal Header -->
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              {{ editarFlag ? 'Editar' : 'Nuevo' }} usuario
            </h2>
          </div>
          <!-- END: Modal Header -->
          <!-- BEGIN: Modal Body -->
          <form>
            <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
              <!-- <div class="col-span-12">
                <div class="w-16 h-16 image-fit">
                  <img
                    v-if="item.urlImage != null && item.urlImage != ''"
                    alt="Placeholder"
                    class="rounded-full"
                    :src="item.urlImage"
                  />
                  <img
                    alt="Placeholder"
                    class="rounded-full"
                    src="@/assets/images/placeholders/200x200.jpg"
                  />
                </div>
              </div> -->
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-1" class="form-label">Email*</label>
                <input
                  id="modal-form-1"
                  type="email"
                  class="form-control"
                  placeholder="example@gmail.com"
                  v-model="email"
                  :disabled="editarFlag"
                />
                <div v-if="v$.email.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-2" class="form-label">Nombres*</label>
                <input
                  id="modal-form-2"
                  type="text"
                  class="form-control"
                  placeholder="Nombres"
                  v-model="userName"
                />
                <div v-if="v$.userName.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-3" class="form-label">Teléfono*</label>
                <input
                  id="modal-form-3"
                  type="tel"
                  class="form-control"
                  placeholder="Telefono"
                  v-model="telefono"
                />
                <div v-if="v$.telefono.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label">Contraseña*</label>
                <input
                  id="modal-form-4"
                  type="password"
                  class="form-control"
                  placeholder="Contraseña"
                  v-model="password"
                />
                <div v-if="v$.password.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label">Dirección*</label>
                <input
                  id="modal-form-4"
                  type="text"
                  class="form-control"
                  placeholder="Dirección"
                  v-model="address"
                />
                <div v-if="v$.address.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label">Ciudad*</label>
                <TomSelect id="ciudades" v-model="cityId" class="w-full">
                  <option selected>Seleccione...</option>
                  <option
                    v-for="item in cities"
                    :value="item.cityId"
                    :key="item.cityId"
                    >{{ item.description }}</option
                  >
                </TomSelect>
                <div v-if="v$.cityId.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label"
                  >Tipo identificación*</label
                >
                <TomSelect
                  id="identificationType"
                  v-model="identificationTypeId"
                  class="w-full"
                >
                  <option
                    v-for="item in listIdentificationTypes"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</option
                  >
                </TomSelect>
                <div v-if="v$.identificationTypeId.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
              <div class="col-span-12 sm:col-span-6">
                <label for="modal-form-4" class="form-label"
                  >Identificación*</label
                >
                <input
                  id="modal-form-4"
                  type="text"
                  class="form-control"
                  placeholder="Identificación"
                  v-model="identificationNumber"
                />
                <div v-if="v$.identificationNumber.$error">
                  <span class="mt-3 text-center font-bold text-theme-5">Campo requerido</span>
                </div>
              </div>
            </div>
          </form>
          <!-- END: Modal Body -->
          <div v-if="errorForm" class="modal-footer text-left col-span-12">
            <span class="font-bold text-theme-5"
              >Todos los campos son necesarios.</span
            >
          </div>
          <!-- BEGIN: Modal Footer -->
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-20 mr-1"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary w-20"
              @click="editarFlag ? updateUser() : crearUsuario()"
            >
              {{ editarFlag ? 'Editar' : 'Crear' }}
            </button>
          </div>
          <!-- END: Modal Footer -->
        </div>
      </div>
    </div>
    <div id="delete-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertTriangleIcon class="w-16 h-16 text-theme-22 mx-auto mt-3" />
              <div class="text-3xl mt-5">¡Atención!</div>
              <div class="text-gray-600 mt-2">
                La eliminación de este registro sera de manera permanente.
                <br />
                ¿Está seguro?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarUsuario()"
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: '',
      password: '',
      userName: '',
      telefono: '',
      urlImage: '',
      address: '',
      cityId: '',
      identificationTypeId: '',
      identificationNumber: '',
      cities: [],
      listIdentificationTypes: [],
      usuarios: [],
      oldUsuarios: [],
      usuarioSeleccionado: {},
      editarFlag: false,
      buscar: '',
      errorForm: false,
      typeList: true
    }
  },
  validations () {
    return {
      userName: { required },
      email: { required },
      password: { required },
      telefono: { required },
      address: { required },
      cityId: { required },
      identificationTypeId: { required },
      identificationNumber: { required }
    }
  },
  methods: {
    initTabulator() {
      this.usuarios = this.gettersUsuariosStore
      this.oldUsuarios = this.usuarios

      const self = this
      this.tabulator = new Tabulator(this.$refs.tableRef, {
        responsiveLayout: 'collapse',
        responsiveLayoutCollapseStartOpen: false,
        layout: 'fitColumns',
        pagination: 'local',
        printAsHtml: true,
        printStyled: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40, 100],
        placeholder: 'No se encontraron registros',
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        },
        data: this.usuarios,
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'IMAGEN',
            minWidth: 200,
            field: 'urlImagen',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return (
                cell.getData().urlImage &&
                `<div class="w-12 h-12 image-fit">
                  <img
                    alt="Logo"
                    class="rounded-full"
                    src="${cell.getData().urlImage}"
                  />
                </div>`
              )
            }
          },
          {
            title: 'Nombres',
            minWidth: 200,
            responsive: 0,
            field: 'userName',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'EMAIL',
            minWidth: 200,
            field: 'email',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CELULAR',
            minWidth: 200,
            field: 'cellphone',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TIPO DE IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identificationName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'IDENTIFICACIÓN',
            minWidth: 200,
            field: 'identification',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'DIRECCIÓN',
            minWidth: 200,
            field: 'address',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CIUDAD',
            minWidth: 200,
            field: 'cityName',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'VALOR GANADO',
            minWidth: 200,
            field: 'accumulatedValue',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          },
          {
            title: 'VALOR GIRADO',
            minWidth: 200,
            field: 'paidValue',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP'
              }).format(cell.getValue())
            }
          },
          {
            title: 'BANCO',
            minWidth: 200,
            field: 'bank',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'CUENTA BANCARIA',
            minWidth: 200,
            field: 'bankAccount',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'TÉCNICO',
            minWidth: 200,
            field: 'isTechnicalMechanic',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isTechnicalMechanic
                  ? 'text-theme-10'
                  : 'text-theme-24'
              }">
                ${cell.getData().isTechnicalMechanic ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'HERRAMIENTAS',
            minWidth: 200,
            field: 'haveTools',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().haveTools ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().haveTools ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'TRANSPORTE',
            minWidth: 200,
            field: 'haveTransport',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().haveTransport ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().haveTransport ? 'SI' : 'NO'}
              </div>`
            }
          },
          {
            title: 'DISPONIBLE',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isAvailable ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isAvailable ? 'Disponible' : 'No disponible'}
              </div>`
            }
          },
          {
            title: 'SESION',
            minWidth: 200,
            field: 'isLogin',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isLogin ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isLogin ? 'Activo' : 'Inactivo'}
              </div>`
            }
          },
          {
            title: '',
            minWidth: 200,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" id="edit">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Editar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.editarUsuario(cell._cell.row.data)
            }
          },
          {
            title: '',
            minWidth: 200,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;" >
                  <i data-feather="trash" class="w-4 h-4 mr-1"></i> Eliminar
                </a>
              </div>`
            },
            cellClick: function(e, cell) {
              self.usuarioSeleccionado = cell._cell.row.data
              self.mensajeEliminar(cell._cell.row.data)
            }
          },
          {
            title: 'INICIO CON GOOGLE',
            minWidth: 200,
            field: 'haveTransport',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().isLoginGoogle ? 'text-theme-10' : 'text-theme-24'
              }">
                ${cell.getData().isLoginGoogle ? 'SI' : 'NO'}
              </div>`
            }
          }
        ]
      })
    },
    showModal() {
      this.v$.$reset()
      this.errorForm = false
      this.editarFlag = false
      cash('#modal-usuarios').modal('show')
    },
    cancelModal() {
      this.email = ''
      this.password = ''
      this.userName = ''
      this.telefono = ''
      this.urlImage = ''
      this.address = ''
      this.cityId = ''
      this.identificationTypeId = ''
      this.identificationNumber = ''
      cash('#modal-usuarios').modal('hide')
    },
    async crearUsuario() {
      const result = await this.v$.$validate()
      if (!result) return
      if (
        this.identificationTypeId == 0 ||
        this.cityId == 0 ||
        !this.userName.trim() ||
        !this.email.trim() ||
        !this.telefono.trim() ||
        !this.password.trim() ||
        !this.address.trim() ||
        !this.cityId.trim() ||
        !this.identificationNumber.trim() ||
        !this.identificationTypeId.trim()
      ) {
        this.errorForm = true
        return
      }
      this.errorForm = false
      await this.setUsuariosStore({
        userName: this.userName.trim(),
        email: this.email.trim(),
        cellphone: this.telefono.trim(),
        userTypeId: 1,
        urlImage: this.urlImage.trim(),
        fcmToken: '',
        isLogin: 0,
        password: this.password.trim(),
        address: this.address.trim(),
        cityId: this.cityId.trim(),
        identification: this.identificationNumber.trim(),
        identificationTypeId: this.identificationTypeId.trim(),
        isLoginGoogle: '',
        uidGoogle: ''
      })
      await this.getUsuariosStore()
      this.initTabulator()
      this.cancelModal()
    },
    mensajeEliminar(data) {
      this.usuarioSeleccionado = data
      cash('#delete-modal').modal('show')
    },
    async eliminarUsuario() {
      this.deleteUsuario(this.usuarioSeleccionado.userId)
      await this.getUsuariosStore()
      this.initTabulator()
      cash('#delete-modal').modal('hide')
    },
    async editarUsuario(data) {
      this.editarFlag = true
      this.email = data.email
      this.userName = data.userName
      this.password = data.password
      this.telefono = data.cellphone
      this.urlImage = data.urlImage
      this.address = data.address
      this.cityId = data.cityId
      this.identificationNumber = data.identification
      this.identificationTypeId = data.identificationTypeId
      this.usuarioSeleccionado = data
      cash('#modal-usuarios').modal('show')
    },
    async updateUser() {
      const result = await this.v$.$validate()
      if (!result) return
      this.setUpdateUser({
        userId: this.usuarioSeleccionado?.userId,
        userName: this.userName.trim(),
        email: this.email.trim(),
        cellphone: this.telefono.trim(),
        urlImage: this.urlImage,
        password: this.password.trim(),
        address: this.address.trim(),
        cityId: this.cityId,
        identification: this.identificationNumber.trim(),
        identificationTypeId: this.identificationTypeId
      })
      await this.getUsuariosStore()
      this.initTabulator()
      this.cancelModal()
    },
    ...mapActions([
      'getUsuariosStore',
      'setUsuariosStore',
      'deleteUsuario',
      'setUpdateUser',
      'getCitiesParameters',
      'getIdentificationsTypes'
    ]) // Esto llama al metodo en el store que trae los usuarios
  },
  computed: {
    ...mapGetters([
      'gettersUsuariosStore',
      'getterCitiesParameters',
      'getterIdentificationsTypes'
    ])
  },
  watch: {
    buscar(newValue, oldValue) {
      let usuariosTemp = []
      if (newValue) {
        usuariosTemp = this.usuarios.filter(usuario =>
          usuario.nombres.toLowerCase().includes(this.buscar.toLowerCase())
        )
      } else if (newValue == '') {
        usuariosTemp = this.oldUsuarios
      }
      this.usuarios = usuariosTemp
    }
  },
  async mounted() {
    await this.getCitiesParameters()
    this.cities = [
      { cityId: 0, description: 'Seleccione' },
      ...this.getterCitiesParameters
    ]
    await this.getIdentificationsTypes()
    this.listIdentificationTypes = [
      { id: 0, name: 'Seleccione' },
      ...this.getterIdentificationsTypes
    ]
    await this.getUsuariosStore()
    this.initTabulator()
  }
})
</script>
