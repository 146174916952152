<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Tu mecanico en linea"
              class="w-6"
              :src="require(`@/assets/images/logo.png`)"
            />
            <span class="text-white text-lg ml-3">
              Tu mecánico en <span class="font-medium">línea</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              Bienvenido <br />
              inicie sesión para poder continuar.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Aquí podrá administrar el sistema de tu mecánico en linea.
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Iniciar sesión
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              inicie sesión para poder continuar. Aquí podrá administrar el
              sistema de tu mecánico en linea.
            </div>
            <div class="intro-x mt-8">
              <form autocomplete="off">
                <input
                  type="text"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                  placeholder="Usuario"
                  v-model="user"
                />
                <input
                  autocomplete="off"
                  type="password"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                  placeholder="Contraseña"
                  v-model="password"
                />
                <div class="mt-3 flex" v-if="error?.length">
                  <span
                    class="mt-3 text-center font-bold text-theme-5"
                    v-for="item in error"
                    :key="item"
                    >{{ item }}</span
                  >
                </div>
              </form>
            </div>
            <div class="intro-x mt-3 xl:mt-3 text-center xl:text-left">
              <button
                @click="signIn"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Ingresar
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  components: {},
  data() {
    return {
      user: '',
      password: '',
      error: []
    }
  },
  methods: {
    ...mapActions(['singInStore']),
    // TODO: momentáneamente se deja validando tamaño, pero las contraseñas seguras deben contener los caracteres siguientes:
    // Debe contener 8 o mas caracteres, al menos una letra, al menos una mayúscula, al menos un numero, al menos un carácter especial.
    isGoodPassword() {
      return this.password?.length > 7
    },
    validationForm() {
      this.error = []
      if (!this.user) {
        console.log('El campo usuario no debe estar vacío')
        this.error.push('El campo usuario no debe estar vacío')
      }
      if (!this.password) {
        console.log('El campo contraseña no debe estar vacío')
        this.error.push('El campo usuario no debe estar vacío')
      }
      if (!this.isGoodPassword()) {
        console.log('La contraseña debe contener mas de 8 caracteres')
        this.error.push('La contraseña debe contener mas de 8 caracteres')
      }
    },
    async signIn() {
      this.validationForm()
      if (this.error.length) {
        return
      }
      const result = await this.singInStore({
        email: this.user,
        password: this.password
      })
      console.log('🚀 ~ signIn ~ result:', result)
      if (result?.token) {
        localStorage.setItem('token', `bearer ${result?.token}`)
        this.$router.replace({
          path: '/home'
        })
      } else {
        this.error = []
        this.error.push('Credenciales invalidas.')
      }
    }
  },
  computed: {
    ...mapGetters(['getterGetToken'])
  },
  mounted() {
    const token = localStorage.getItem('token')
    token &&
      this.$router.replace({
        path: '/home'
      })
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  }
})
</script>
