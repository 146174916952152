import { createStore } from 'vuex'
import sideMenu from './side-menu'
import tumecanicoStore from './tumecanicoenlinea-store'
import auth from './auth'

const store = createStore({
  modules: {
    sideMenu,
    tumecanicoStore,
    auth
  }
})

export function useStore() {
  return store
}

export default store
