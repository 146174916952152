import axios from 'axios'

// const _baseUrl = 'http://localhost:3000'
const _baseUrl = 'https://tmel.gobeapp.com'

export const routes = {
  users: `${_baseUrl}/user/all`,
  createUser: `${_baseUrl}/user/client`,
  wallets: `${_baseUrl}/wallet/all`,
  pays: `${_baseUrl}/payments/all`,
  config: `${_baseUrl}/parameter`,
  cities: `${_baseUrl}/city`,
  sigIn: `${_baseUrl}/auth/sign-in`,
  history: `${_baseUrl}/service/admin/allServices`,
  identificationsTypes: `${_baseUrl}/user/identifications`,
  updateUser: `${_baseUrl}/user/admin/client`,
  deleteUser: `${_baseUrl}/user/admin/client/delete`,
  garage: `${_baseUrl}/user/garage`,
  garageUpdate: `${_baseUrl}/user/garage-admin`,
  createMechanic: `${_baseUrl}/user/mechanic`,
  updateMechanic: `${_baseUrl}/user/admin/mechanic`,
  crane: `${_baseUrl}/user/crane`,
  craneUpdate: `${_baseUrl}/user/crane-admin`,
  payments: `${_baseUrl}/payments`
}

export const getServices = async (url) => {
  defaultConfig()
  try {
    const response = await axios.get(url)
    const result = responseHandled(response)
    return result
  } catch (error) {
    return {
      success: false,
      data: error
    }
  }
}

export const postServices = async (url, params) => {
  defaultConfig()
  try {
    const response = await axios.post(url, params)
    const result = responseHandled(response)
    return result
  } catch (error) {
    return {
      success: false,
      data: error
    }
  }
}

export const putServices = async (url, params) => {
  defaultConfig()
  try {
    const response = await axios.put(url, params)
    const result = responseHandled(response)
    return result
  } catch (error) {
    return {
      success: false,
      data: error
    }
  }
}

export const deleteServices = async (url) => {
  defaultConfig()
  try {
    const response = await axios.delete(url)
    const result = responseHandled(response)
    return result
  } catch (error) {
    return {
      success: false,
      data: error
    }
  }
}

function responseHandled(response) {
  if (response && response.status == 200) {
    return {
      success: true,
      data: response?.data
    }
  }
  return {
    success: false,
    data: response
  }
}

function defaultConfig() {
  const AUTH_TOKEN = getToken()
  axios.defaults.headers.common.Authorization = AUTH_TOKEN
}

function getToken() {
  const token = localStorage.getItem('token')
  return token
}

export function setToken(token) {
  localStorage.setItem('token', token)
}

export function clearLocalStorage() {
  localStorage.clear()
}
