import { routes, getServices, postServices, putServices, deleteServices } from './api-services'

export const getUsers = async (typeUser) => {
  try {
    const { data } = await getServices(`${routes.users}?typeId=${typeUser}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const getWallets = async () => {
  try {
    const { data } = await getServices(`${routes.wallets}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const getPayments = async () => {
  try {
    const { data } = await getServices(`${routes.pays}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const getParameters = async () => {
  try {
    const { data } = await getServices(`${routes.config}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const getCityList = async () => {
  try {
    const { data } = await getServices(`${routes.cities}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const signInUser = async ({ email, password }) => {
  try {
    const { data } = await postServices(`${routes.sigIn}`, { email, password })
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const historyServices = async () => {
  try {
    const { data } = await getServices(`${routes.history}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const createUser = async (body) => {
  try {
    const { data } = await postServices(`${routes.createUser}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const identificationsTypes = async () => {
  try {
    const { data } = await getServices(`${routes.identificationsTypes}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const updateUser = async (body) => {
  try {
    const { data } = await putServices(`${routes.updateUser}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const deleteUser = async (id) => {
  try {
    const { data } = await deleteServices(`${routes.deleteUser}?id=${id}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const createGarage = async (body) => {
  try {
    const { data } = await postServices(`${routes.garage}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const updateGarage = async (body) => {
  try {
    const { data } = await putServices(`${routes.garageUpdate}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const createMechanic = async (body) => {
  try {
    const { data } = await postServices(`${routes.createMechanic}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}

/**
 * Update mechanic receive a object, if the api response OK, return the object inserted
 * @param {
 *    userName: String,
 *    email: String,
 *    cellphone: String,
 *    password: String,
 *    identification: String,
 *    bank: String,
 *    bankAccount: String,
 *    brands: String,
 *    services: String,
 *    haveTools: boolean,
 *    haveTransport: boolean,
 *    isTechnicalMechanic: boolean,
 *    cityId: Number,
 *    address: String,
 *    isAvailable: boolean,
 *    userTypeId: Number,
 *    urlImage: String,
 *    fcmToken: String,
 *    isLogin: Number,
 *    identificationTypeId: Number,
 *    identificationName: String,
 *    identificationAbr: String,
 *    latitud: String,
 *    longitud: String,
 *    isLoginGoogle: boolean,
 *    uidGoogle: String
 * } body
 * @returns
 */
export const updateMechanic = async (body) => {
  try {
    const response = await putServices(`${routes.updateMechanic}`, body)
    return response?.success
  } catch (error) {
    throw Error(error)
  }
}

/**
 *
 * @param {
 *  userName: '',
 * email: '',
 * cellphone: '',
 * password: '',
 * bank: '',
 * bankAccount: '',
 * cityId: 0,
 * address: '',
 * identification: '',
 * identificationTypeId: 0,
 * owner: '',
 * isAvailable: false,
 * latitud: '',
 * longitud: '',
 * brands: '',
 * services: '',
 * phone2: '',
 * userTypeId: 4,
 * urlImage: '',
 * fcmToken: '',
 * isLogin: 0,
 * isLoginGoogle: false,
 * uidGoogle: ''} body
 * @returns
 */
export const createCrane = async (body) => {
  try {
    const { success, data } = await postServices(`${routes.crane}`, body)
    return { success, data }
  } catch (error) {
    throw Error(error)
  }
}

/**
 * @param {
 *  userName: '',
 * email: '',
 * cellphone: '',
 * password: '',
 * bank: '',
 * bankAccount: '',
 * cityId: 0,
 * address: '',
 * identification: '',
 * identificationTypeId: 0,
 * owner: '',
 * isAvailable: false,
 * latitud: '',
 * longitud: '',
 * brands: '',
 * services: '',
 * phone2: '',
 * userTypeId: 4,
 * urlImage: '',
 * fcmToken: '',
 * isLogin: 0,
 * isLoginGoogle: false,
 * uidGoogle: ''} body
 * @returns
 */
export const updateCrane = async (body) => {
  try {
    const { success, data } = await putServices(`${routes.craneUpdate}`, body)
    return { success, data }
  } catch (error) {
    throw Error(error)
  }
}

/**
 * @param body {
 * "id":3,
 * "value":20000,
 * "paymentUserId":15,
 * "paymentTypeId":1,
 * "date":"2024-05-24",
 * "referenceCode":"123456"
 * @returns
 */
export const createPayment = async (body) => {
  try {
    const { success, data } = await postServices(`${routes.payments}`, body)
    return { success, data }
  } catch (error) {
    throw Error(error)
  }
}

export const deletePayment = async (id) => {
  try {
    const { data } = await deleteServices(`${routes.payments}/${id}`)
    return data
  } catch (error) {
    throw Error(error)
  }
}

export const updateParameters = async (body) => {
  try {
    const { data } = await putServices(`${routes.config}`, body)
    return data
  } catch (error) {
    throw Error(error)
  }
}
